import { Link, graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Seo from '../components/Seo';
import ContentHeader from '../components/ContentHeader/ContentHeader';

const BlogIndex = ({ data, location }) => {
  const posts = data.posts.nodes;
  const pageData = data.page;

  const content =
    posts.length === 0 ? (
      <p>Bisher keine Blogposts erschienen.</p>
    ) : (
      <>
        <h2 className="Section__title">Bisherige Posts</h2>
        <ol className="Podcast__list">
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug;

            return (
              <li
                key={post.fields.slug}
                className={'Podcast__item'}
                style={{ opacity: 1 }}
              >
                <Link to={post.fields.slug} itemProp="url">
                  <article itemScope itemType="http://schema.org/Article">
                    <div>
                      <h3>
                        <span itemProp="headline">{title}</span>
                      </h3>
                      <section
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </div>
                  </article>
                </Link>
              </li>
            );
          })}
        </ol>
      </>
    );

  return (
    <Layout location={location}>
      <Seo
        title={pageData.frontmatter.seoTitle || pageData.frontmatter.title}
        location={location}
        description={pageData.frontmatter.description}
      />
      <ContentHeader title={pageData.frontmatter.title} />
      <Section>
        <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
      </Section>
      <Section type="turquoise-light" id="list">
        {content}
      </Section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/blog/" } }) {
      frontmatter {
        title
        seoTitle
        description
      }
      html
    }
    posts: allMarkdownRemark(
      filter: { fields: { slug: { glob: "/blog/*" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "D. MMMM YYYY", locale: "de-DE")
          title
          templateKey
          description
        }
      }
    }
  }
`;
